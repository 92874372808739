.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scene4{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template:
    "a c" 100% / 90% 10%;
    overflow: hidden;
    min-height: 650px;
}

.cv-wrapper{
    grid-area: a;
    width: 90%;
    height: 90%;
    margin: 5%;
    align-self: center;
    justify-self: center;
    display: flex;
}

.cv-container{
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    width: 60%;
    height: 100%;
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: 14% 14% 14% 14% 14% 14% 14%;
    align-items: center;
}

.cv-button{
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1vw;
    background-color: #ff7323;
    border: none;
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    cursor: pointer;
    grid-column: 2;
}

.cv-button-wrapper{
    align-self: center;
    justify-self: flex-end;
    width: 90%;
    height: 85%;
}

.cv-line{
    height: 70%;
    width: 5rem;
    border-radius: 3rem;
    background-color: #6D6D6D;
    /* grid-row: 1/5;
    grid-column: 1; */
    /* position: relative; */
}

.cv-text{
    display: flex;
    flex-direction: column;
    grid-column: 1;
}

.cv-back-triangle-1 {
    fill: #464646;
    stroke: #464646;
    stroke-width: 25;
}

.cv-back-triangle-1-container{
    position: absolute;
    width: 30%;
    z-index: -1;
    filter: drop-shadow(10px 5px 10px #17171726);
    top: -25%;
    left: -2%;
}

.cv-back-triangle-2 {
    fill: #101010;
    stroke: #101010;
    stroke-width: 20;
}

.cv-back-triangle-2-container{
    position: absolute;
    width: 45%;
    z-index: -1;
    filter: drop-shadow(10px 5px 10px #17171726);
    bottom: -25%;
    left: 15%;
}

.cv-back-triangle-3 {
    fill: #1C1C1C;
    stroke: #1C1C1C;
    stroke-width: 20;
}

.cv-back-triangle-3-container{
    position: absolute;
    width: 30%;
    z-index: -1;
    filter: drop-shadow(10px 5px 10px #17171726);
    top: 3%;
    right: 2%;
}

.cv-back-triangle-4 {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    stroke-width: 20;
}

.cv-back-triangle-4-container{
    position: absolute;
    width: 15%;
    z-index: -1;
    filter: drop-shadow(10px 5px 10px #17171726);
    bottom: 0%;
    right: 10%;
}

.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}