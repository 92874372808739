.rating-dot{
    border: none;
    border-radius: 100%;
    height: 1.75rem;
    width: 1.75rem;
}

.rating-dot.unmarked{
    background-color: #6D6D6D;
}

.rating-dot.marked{
    background-color: #ff7323;
}

.rating-wrapper{
    display: grid;
    grid-template-columns: auto 15rem;
    align-items: center;
}

.rating-points{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin-right: 2rem;
}

.rating-wrapper-info{
    display: grid;
    grid-template-columns: auto 6rem;
    grid-gap: 10px;
    align-items: center;
    margin-left: .5rem;
    margin-right: .5rem;
}

.rating-points-info{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin-right: 1rem;
}

.rating-dot-info{
    border: none;
    border-radius: 100%;
    height: .75rem;
    width: .75rem;
}

.rating-dot-info.unmarked{
    background-color: #6D6D6D;
}

.rating-dot-info.marked{
    background-color: #ff7323;
}

.rating-caption-info{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: .7vw !important;
    font-weight: 200;
    font-style: italic;
    line-height: normal;
    white-space: nowrap;
}

.rating-wrapper-mobile{
    display: grid;
    grid-template-columns: 60% auto;
    align-items: center;
}

.rating-points-mobile{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    width: 100%;
    justify-items: center;
}

.rating-dot-mobile{
    border: none;
    border-radius: 100%;
    height: 4vw;
    width: 4vw;
}

.rating-dot-mobile.unmarked{
    background-color: #6D6D6D;
}

.rating-dot-mobile.marked{
    background-color: #ff7323;
}