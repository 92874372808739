.contact-icon{
    display: flex;
    align-self: center;
    justify-self: center;
    height: 1000%;
    width: 100%;
    max-width: max(7vh, 1rem);
    max-height: max(7vh, 1rem);
}

.contact-icon-link{
    height: 100%;
    width: 100%;
    display: flex;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
}

.contact-icon-nolink{
    border: none;
    background-color: transparent;
    height: 100%;
    width: 100%;
    display: flex;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
}

.contact-icon-email{
    cursor: pointer;
}

.contact-email{
    grid-column: 2;
    grid-row: 1;
}

.contact-form{
    grid-column: 3;
    grid-row: 2;
}

.contact-xing{
    grid-column: 3;
    grid-row: 4;
}

.contact-linkedin{
    grid-column: 2;
    grid-row: 5;
}

.contact-instagram{
    grid-column: 1;
    grid-row: 4;
}

.contact-twitter{
    grid-column: 1;
    grid-row: 2;
}