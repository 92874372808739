.contact-email-window-wrapper{
    align-self: flex-end;
    grid-column: 2;
    grid-row: 1;
    width: auto;
    height: 3rem;
    justify-self: center;
}

.contact-email-window-filler {
	position: relative;
	background-color: #242424;
	text-align: left;
    transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}
.contact-email-window-filler:before,
.contact-email-window-filler:after {
	content: '';
	position: absolute;
	background-color: inherit;
}

.contact-email-window-filler:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}

.contact-email-window-filler:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.contact-email-window-filler,
.contact-email-window-filler:before,
.contact-email-window-filler:after {
    margin: 0 auto;
	width:  min(1rem, 2vh);;
	height: min(1rem, 2vh);;
	border-top-right-radius: 30%;
}

.contact-email-window-container{
    margin: 0;
    height: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 80% 10% 10%;
    /* grid-column-gap: 1rem; */
    align-items: center;
    justify-items: center;
    background-color: #242424;
    border-radius: 3rem;
}

.contact-email-window-text{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: .7vw;
    font-weight: 700;
    line-height: normal;
    align-self: center;
    justify-self: center;
    line-height: 0;
    width: fit-content;
    white-space: nowrap;
}

.contact-email-window-copy-wrapper{
    cursor: pointer;
    height: 75%;
    width: auto;
    align-self: center;
    justify-self: center;
    border: none;
    background-color: transparent;
    grid-column: 2;
}

.contact-email-window-copy{
    height: 100%;
    width: 100%;
}

.contact-email-window-close{
    grid-column: 3;
    cursor: pointer;
    height: 50%;
    width: auto;
    align-self: center;
    justify-self: center;
}