.nav-wrapper{
    grid-area: c;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: auto;
    margin: 0 auto;
}

.nav-container-container{
    height: 100%;
    width: 2rem;
}

.nav-container-buttons {
    height: 100%;
    display: grid;
    grid-template-rows: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-items: center;
    z-index: 98;
}

.nav-element{
    z-index: 99;
}

.nav-button{
    z-index: 100;
    border-radius: 100%;
    border: none;
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.nav-vert-line{
    height: 100%;
    width: 2px;
    border-radius: 1rem;
    background-color: #C9C9C9;
    margin: 0 auto;
    position: relative;
    transform: translateY(-100%);
    z-index: -1;
}

.nav-button-unselected{
    background-color: #C9C9C9;
}

.nav-button-selected{
    background-color: #ff7323;
}

.nav-container-text{
    z-index: 20;
    transform: translateY(-100%) translateX(-80%);
    position: absolute;
    height: 100%;
    display: grid;
    grid-template-rows: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-items: center;
    user-select: none;
}

.nav-text{
    transform: rotate(-90deg);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    cursor: pointer;
}

.nav-text-selected{
    color: #ff7323;
}

.nav-text-unselected{
    color: #C9C9C9;
}