.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}

.intro-text-mobile{
    width: auto;
    align-self: flex-start;
    justify-self: flex-start;
    grid-area: a;
    padding: 5vw;
    color: #ffffff;
    z-index: 10;
    max-height: 30%;
}

.intro-text-mobile h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 7vw;
    font-weight: 700;
    mix-blend-mode: difference;
    padding: .5rem;
    margin: 0;
}

.mobile-wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.intro-back-slash{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.me-wrapper-mobile{
    position: relative;
    width: auto;
    height: fit-content;
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 1rem;
}

.me-pic-container-mobile{
    width: 100%;
    height: 100%;
}

.me-picture-mobile{
    width: 40vw;
    border-radius: 7vw;
    filter: drop-shadow(5px 5px 10px #1b1b1b5b);
    mix-blend-mode: difference;
}

.me-socials-wrapper-mobile{
    height: 100%;
    width: fit-content;
    display: grid;
    grid-template-rows: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-items: center;
    mix-blend-mode: difference;
    align-self: center;
    position: absolute;
    top: 0;
    right: 0;
}

.me-social-mobile{
    padding: 5px;
    margin: 5px;
    width: 7vw;
    height: auto;
}

.interests-wrapper-mobile{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: fit-content;
    color: #707070;
}

.interests-mobile{
    margin-block-start: 0px !important;
    margin-block-end: 0;
    padding-left: 5vw;
    font-weight: 400;
    font-size: 4vw;
    white-space: nowrap;
    justify-self: flex-start;
}

.interests-header-mobile{
    width: 100%;
    font-size: 4vw;
    line-height: 0px;
    display: grid;
    grid-template-columns: 5vw min-content auto;
    align-items: center;
}

.me-interest-wrapper-mobile{
    width: 100vw;
    display: grid;
    grid-template-columns: 50% 50%;
}

.interest-header-line-mobile{
    width: auto;
    height: 2px;
    background-color: #707070;
    border-radius: 2rem;
    margin-right: .25rem;
    margin-left: .25rem;
}

.interest-header-title{
    width: fit-content;
}

.skills-container-mobile{
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    margin-left: 5vw;
    z-index: 10;
}

.skills-wrapper-mobile{
    /* position: absolute; */
    height: 80%;
    width: 80%;
    grid-auto-flow: column;
    align-items: center;
    align-self: center;
    justify-self: center;
    z-index: 10;
}

.skills-wrapper-mobile h1{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 5.5vw;
    font-weight: 700;
    z-index: 10;
    height: 100%;
    width: 100%;
    white-space: nowrap;
}

.skills-wrapper-mobile p{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
    white-space: nowrap;
    z-index: 10;
    height: 100%;
    width: 100%;
    align-self: center;
    margin: auto;
}

.cv-container-mobile{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 5% 5% auto;
}

.cv-vertical-line{
    width: 3px;
    border-radius: 1rem;
    background-color: #C9C9C9;
    grid-row: 1/8;
    grid-column: 1;
    z-index: 0;
    justify-self: flex-end;
    transform: translateX(50%);
}

.cv-wrapper-mobile{
    height: 100%;
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.cv-element-marker-mobile{
    justify-self: center;
    height: 3.5vw;
    width: 3.5vw;
    background-color: #ff7323;
    border-radius: 100%;
    margin-top: .8rem;
    grid-column: 2;
    justify-self: flex-start;
    z-index: 10;
    transform: translateX(-50%);
}

.cv-button-wrapper-mobile{
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 4vw;
    background-color: #ff7323;
    border: none;
    border-radius: 25rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 2rem;
    align-self: flex-end;
    text-decoration: none;
}

.headers-mobile{
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 8vw;
    text-align: left;
    line-height: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 5vw;
    z-index: 100;
}

.contact-wrapper-mobile{
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
}

.contact-text-hover-mobile{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 5vw;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    align-self: center;
}

.contact-icons-mobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.skills-lang{
    grid-row: 1/4;
    align-self: center;
}

.skills-code{
    grid-row: 6/9;
    align-self: center;
}

.skills-softw{
    grid-column: 2;
    grid-row: 1/4;
    align-self: center;
}