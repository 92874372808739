.home-wrapper{
    height: 100%;
    width: 100%;
    min-width: 1300px;
    min-height: 700px;
    background: linear-gradient(-45deg, #4b4b4b31, #ffffff);
}

.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cards{
    margin: 0 auto;
    position: relative;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 700px;
    /* min-width: 100vw; */
}