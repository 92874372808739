.cv-element{
    display: grid;
    grid-template-columns: 10% 90%;
    width: 100%;
    height: 85%;
    background-color: #FFFFFF;
    border-radius: 1.5rem;
    align-self: center;
    justify-self: center;
    grid-column: 1/3;
}

.cv-element-last{
    grid-column: 1;
}

.cv-element-marker{
    align-self: center;
    justify-self: flex-start;
    height: 70%;
    width: 1.5rem;
    margin-left: 1rem;
    background-color: #ff7323;
    border-radius: 2rem;
}

.cv-element-txt{
    font-weight: 400;
    color: #6D6D6D;
    white-space: nowrap;
    align-self: center;
    font-family: 'Poppins', sans-serif;
    line-height: normal;
    font-size: .8vw;
    /* margin-left: 1rem; */
    display: grid;
    grid-template-columns: 15% 25% 50% 10%;
    height: 100%;
    width: 100%;
    max-height: 100%;
    justify-items: flex-start;
    align-items: center;
}

.cv-link-container{
    height: 2rem;
    width: auto;
    display: flex;
    justify-content: flex-end;
}

.cv-link{
    height: 100%;
}

.cv-link-icon{
    height: 100%;
}

.cv-top-text{
    font-weight: 700;
    font-style: italic;
}

.cv-element-date{
    font-weight: 700;
    color: #ff7323;
}

.cv-element-date-wrapper{
    text-align: center;
    line-height: 130%;
}

.cv-element-sublink{
    color: #6D6D6D;
    text-decoration-style: dashed;
    text-decoration-thickness: .1em;
    text-underline-offset: 2px;
}

.cv-element-sublink:visited{
    color: #6D6D6D;
}

.cv-element-sublink:active{
    color: #ff7323;
}

.cv-element-mobile{
    width: 100%;
    height: 85%;
    align-self: center;
    border-radius: 1.5rem;
    align-self: center;
    justify-self: center;
    grid-column: 3;
    margin-bottom: 1rem;
    margin-top: .5rem;
}

.cv-element-txt-mobile{
    font-weight: 400;
    color: #6D6D6D;
    align-self: center;
    font-family: 'Poppins', sans-serif;
    line-height: normal;
    font-size: 4vw;
    width: 90%;
}

.cv-element-date-mobile{
    font-weight: 700;
    color: #ff7323;
}

.cv-element-sublink-mobile{
    color: #6D6D6D;
    text-decoration-style: dashed;
    text-decoration-thickness: .1em;
    text-underline-offset: 2px;
}

.cv-element-date-mobile{
    font-weight: 700;
    color: #FFFFFF;
}
