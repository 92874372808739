@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

.App{
  background-color: rgb(141, 141, 141);
}

/* SCROLLBAR STYLE */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  width: 15px;
  background: #f1f1f1;
  border-radius: 3rem;
}

::-webkit-scrollbar-thumb {
  background: rgb(78, 78, 78);
  border-radius: 3rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70);
}

::selection {
  background: #FF8B46;
}