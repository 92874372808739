@keyframes fadeIn {
    0% {
        transform: translate(-55%, -50%);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scene1{
    animation: fadeIn .5s ease-in-out;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template:
    "a b c" 50%
    "d b c" 50% / 55% 35% 10%;
    min-height: 650px;
}

.intro-text{
    width: 100%;
    align-self: flex-start;
    justify-self: flex-start;
    grid-area: a;
    padding: 3vw;
    color: #ffffff;
    z-index: 10;
    max-height: 30%;
}

.intro-text h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3.5vw;
    font-weight: 700;
    line-height: 3rem;
    white-space: nowrap;
    mix-blend-mode: difference;
}

.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}

.sig-dot-inverted{
    color: #0983D3;
    mix-blend-mode: normal !important;
}

.interests-wrapper{
    align-self: flex-end;
    justify-self: flex-start;
    font-family: 'Poppins', sans-serif;
    grid-area: d;
    width: 100%;
    height: auto;
    max-height: 70%;
    margin-bottom: 5rem;
    color: #707070;
}

.interests-header{
    width: 100%;
    font-size: 1.5vw;
    line-height: 0px;
    display: grid;
    grid-template-columns: 3vw min-content auto;
    /* grid-column-gap: .5rem; */
    align-items: center;
}

.interest-header-line{
    width: auto;
    height: 2px;
    background-color: #707070;
    border-radius: 2rem;
    margin-right: .5rem;
    margin-left: .5rem;
}

.interest-header-title{
    width: fit-content;
}

.interests{
    margin-block-start: 0px !important;
    margin-block-end: 0;
    display: grid;
    padding-left: 3vw;
    grid-template-columns: 50% 50%;
    font-weight: 400;
    font-size: 2.25vw;
    white-space: nowrap;
}

.me-wrapper{
    position: relative;
    grid-area: b;
    align-self: flex-end;
    justify-self: center;
    /* max-height: 50%; */
    width: 30vh;
    max-width: 50%;
    margin-bottom: 3rem;
}

.me-contianer{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 80% 20%;
}

.me-picture{
    width: 100%;
    height: auto;
    border-radius: 5rem;
    filter: drop-shadow(5px 5px 10px #1b1b1b5b);
    mix-blend-mode: difference;
}

.me-subtext{
    position: absolute;
    bottom: 20%;
    right: 0%;
    z-index: 10;
    font-family: 'Poppins', sans-serif;
    font-size: 150%;
    font-weight: 400;
    z-index: 10;
    color: #ffffff;
    mix-blend-mode: difference;
    padding: 1rem;
    white-space: nowrap;
}

.me-pic-container{
    width: 100%;
    /* max-width: 100%; */
    height: auto;
}


.me-socials-wrapper{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-items: center;
    padding: none;
    margin: none;
}

.me-blogref{
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 2rem;
    margin-block-start: 0px;
    margin-block-end: 0px;
    justify-self: center;
    align-self: flex-end;
    /* transform: translateX(11.5vh); */
}

#me-blogref-link{
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    text-decoration: none;
    color: #ff7323;
    background-color: transparent;
    border: none;
    display: inline-block;
}

.me-blogref :visited{
    text-decoration: none;
    color: #ff7323;
}

.me-social{
    /* height: auto; */
    margin: 5px;
    max-width: 70%;
}