.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scene5{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template:
    "a c" 100% / 90% 10%;
    overflow: hidden;
    position: relative;
}

.contact-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.contact-circle-form{
    margin: 0 auto;
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 12.5% 22.5% 30% 22.5% 12.5%;
    align-items: center;
    justify-items: center;
}

.contact-small-circle{
    padding-top: 150%;
    width: 150%;
    background-color: #343434;
    border: none;
    border-radius: 100%;
    z-index: 1;
}

.contact-mid-circle{
    padding-top: 155%;
    width: 155%;
    /* max-height: 70vh;
    max-width: 70vh; */
    background-color: #929292;
    border: none;
    border-radius: 100%;
    z-index: 2;
}

.contact-big-circle{
    height: 40vw;
    width: 40vw;
    background-color: #FFFFFF;
    border: none;
    border-radius: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    1% { transform: rotate(1deg); }
    2% { transform: rotate(0deg); }
    3% { transform: rotate(-1deg); }
    4% { transform: rotate(0deg); }
    5% { transform: rotate(1deg); }
    6% { transform: rotate(0deg); }
    7% { transform: rotate(-1deg); }
    8% { transform: rotate(0deg); }
    9% { transform: rotate(1deg); }
    10% { transform: rotate(0deg); }
    11% { transform: rotate(-1deg); }
    12% { transform: rotate(0deg); }
    13% { transform: rotate(1deg); }
    14% { transform: rotate(0deg); }
    15% { transform: rotate(-1deg); }
    16% { transform: rotate(0deg); }
}

.contact-text{
    animation: wiggle 5s infinite ease-in-out 2s;
    padding: 5rem;
    color: #ff7323;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 4vw;
    font-weight: 700;
    line-height: normal;
}

.contact-hover{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
    grid-template-rows: 20% 20% 20% 20% 20%;
    justify-self: center;
    align-self: center;
    border-radius: 100%;
    margin: 0 auto;
}

.contact-link-wrapper{
    width: 30%;
    height: auto;
    display: flex;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
}

.contact-link-wrapper :hover {
    cursor: pointer;
}

.contact-icons-div{
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
}

.contact-text-hover{
    color: #242424;
    font-family: 'Poppins', sans-serif;
    font-size: 3vw;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    align-self: center;
}

.contact-icon-email{
    cursor: pointer;
}

.contact-email{
    grid-column: 2;
    grid-row: 1;
}

.contact-form{
    grid-column: 3;
    grid-row: 2;
}

.contact-xing{
    grid-column: 3;
    grid-row: 4;
}

.contact-linkedin{
    grid-column: 2;
    grid-row: 5;
}

.contact-instagram{
    grid-column: 1;
    grid-row: 4;
}

.contact-twitter{
    grid-column: 1;
    grid-row: 2;
}

.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}