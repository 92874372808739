.ContactForm-Wrapper{
    position: absolute;
    background-color: #6d6d6dc7;
    box-shadow: 5px 5px 10px #00000067;
    backdrop-filter: blur(12px);
    border-radius: 1rem;
    max-width: 70%;
    width: 70%;
    height: 80%;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.ContactForm-Form-elm{
    font-family: 'Poppins', sans-serif;
    font-size: min(1.15rem, 1vw);
    font-weight: 500;
    color: #242424;
    margin: .5rem;
    border-radius: .4rem;
    border: none;
    padding: .75rem;
}

.ContactForm-Form-Close{
    position: relative;
    height: .75rem;
    width: 1rem;
    border-radius: .5rem;
    border: none;
    align-self: flex-end;
    margin: .25rem;
    margin-right: 1rem;
    background-color: #FF8B46;
    cursor: pointer;
}

.ContactForm-Form{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 10% 10% 70% 10%;
}

.cf-FName.cf-LName{
    grid-row: 1;
}

.cf-EMail{
    grid-column: 1/3;
    grid-row: 2;
}

.cf-Message{
    grid-column: 1/3;
    grid-row: 3;
    resize: none;
}

.cf-Send{
    grid-column: 2;
    padding: 0;
    cursor: pointer;
}

.ContactForm-Form-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactForm-Form-sending-anim-wrapper{
    width: 15%;
    width: 15%;
}

.ContactForm-Form-error-wrapper{
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    border-radius: 1rem;
    background: #232323;
    padding: 1rem;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ContactForm-Form-error-button{
    background-color: #FF8B46;
    border: none;
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    font-weight: 500;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    cursor: pointer;
    z-index: 1;
    color: white;
}

.ContactForm-Form-error-anim-wrapper{
    width: 15%;
    width: 15%;
    position: absolute;
    z-index: 0;
    opacity: 0.25;
}

.ContactForm-Form-error-message{
    z-index: 1;
    text-align: center;
}