.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scene2{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template:
    "a c" 100% / 90% 10%;
    overflow: hidden;
    min-height: 650px;
}

.skills-container{
    height: 100%;
    width: 100%;
    grid-area: a;
    display: flex;
    align-content: center;
    justify-content: center;
}

.skills-wrapper{
    position: absolute;
    height: 80%;
    width: 80%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(18, 5.55%);
    grid-auto-flow: column;
    align-items: center;
    align-self: center;
    justify-self: center;
}

.skills-lang{
    grid-row: 1/4;
    align-self: center;
}

.skills-code{
    grid-row: 6/9;
    align-self: center;
}

.skills-softw{
    grid-column: 2;
    grid-row: 1/4;
    align-self: center;
}

.skills-wrapper h1{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5vw;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

.skills-wrapper p{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5vw;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
}

.explenation-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    align-self: center;
    justify-self: center;
}

.explenation-wrapper{
    display: flex;
    justify-content: center;
}

.skills-back-circle-big{
    width: 80%;
    padding-top: 80%;
    background-color: #101010;
    border: none;
    border-radius: 100%;
    z-index: -1;
    position: absolute;
    left: -60%;
    align-self: center;
    filter: drop-shadow(10px 5px 10px #17171726);
}

.skills-back-circle-medium{
    width: 30%;
    padding-top: 30%;
    background-color: #1C1C1C;
    border: none;
    border-radius: 100%;
    z-index: -1;
    position: absolute;
    right: 20%;
    bottom: -20%;
    filter: drop-shadow(10px 5px 10px #17171726);
}

.skills-back-circle-small{
    width: 15%;
    padding-top: 15%;
    background-color: #6D6D6D;
    border: none;
    border-radius: 100%;
    z-index: -1;
    position: absolute;
    right: 5%;
    top: -12%;
    filter: drop-shadow(10px 5px 10px #17171726);
}

.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}