.scene{
    position: absolute;
    border-radius: 3rem;
    border: none;
    box-shadow: 15px 15px 20px #24242460;
    overflow-y: hidden;
    align-self: center;
    justify-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scene3{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template:
    "a c" 100% / 90% 10%;
    overflow: hidden;
    min-height: 650px;
}

.portfolio-wrapper{
    grid-area: a;
    width: fit-content;
    height: fit-content;
    align-self: center;
    justify-self: center;
}

.portfolio-wrapper h1{
    text-align: center;
    color: #DBDBDB;
    font-size: min(5rem, 8vh);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: normal;
    margin: 2.5rem;
    padding: 0;
}

.portfolio-back-left{
    height: 100vh;
    width: 40vw;
    max-height: 100vh;
    max-width: 40vw;
    background-color: #6D6D6D;
    border: none;
    border-radius: 12rem;
    z-index: -1;
    position: absolute;
    left: -30vw;
    top: 0;
    filter: drop-shadow(10px 5px 10px #00000026)
}

.portfolio-back-right{
    height: 100vh;
    width: 40vw;
    max-height: 100vh;
    max-width: 40vw;
    background-color: #1C1C1C;
    border: none;
    border-radius: 12rem;
    z-index: -1;
    position: absolute;
    right: -30vh;
    bottom: -40vh;
}

.portfolio-text-wrapper{
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.sig-dot{
    color: #ff7323;
    mix-blend-mode: normal !important;
}

.portfolio-wrapper{
    width: 90%;
    height: 90%;
}

.portfolio-tiles-wrapper{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
}

.portfolio-tile{
    width: 80%;
    height: 80%;
    background-color: #101010;
    border-radius: 3rem;
    color: #FFFFFF;
    font-size: 3.1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-align: center;
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
